import React from "react";
import LeftSideBar from "./LeftSideBar";
import MainContainer from "./MainContainer";

function Main() {
  return (
    <div className="App">
      <LeftSideBar />
      <MainContainer />
    </div>
  );
}

export default Main;
