import logo from "./logo.svg";
import editIcon from "./editIcon.svg";
import highLight from "./highLight.svg";
import iconContained1 from "./iconContained1.png";
import iconContained2 from "./iconContained2.png";
import iconContained3 from "./iconContained3.png";
import iconContained4 from "./iconContained4.png";
import iconContained5 from "./iconContained5.png";
import iconContained11 from "./iconContained11.png";
import iconContained12 from "./iconContained12.png";
import iconContained13 from "./iconContained13.png";
import iconContained14 from "./iconContained14.png";
import iconContained15 from "./iconContained15.png";
import iconContained16 from "./iconContained16.png";
import iconContained20 from "./iconContained20.png";
import iconContained21 from "./iconContained21.png";
import iconContained22 from "./iconContained22.png";
import iconContained23 from "./iconContained23.png";
import iconContained24 from "./iconContained24.png";
import iconContained25 from "./iconContained25.png";
import iconContained26 from "./iconContained26.png";
import iconContained27 from "./iconContained27.png";
import iconContained28 from "./iconContained28.png";
import iconContained29 from "./iconContained29.png";
import signalLogo from "./signalLogo.png";
import signalColorLogo from "./signalColorLogo.png";
import academy from "./academy.svg";
import discord from "./discord.svg";
import gitbook from "./gitbook.svg";
import medium from "./medium.svg";
import play from "./play.svg";
import twitter from "./twitter.svg";

export default {
  logo,
  editIcon,
  highLight,
  iconContained1,
  iconContained2,
  iconContained3,
  iconContained4,
  iconContained5,
  iconContained11,
  iconContained12,
  iconContained13,
  iconContained14,
  iconContained15,
  iconContained16,
  iconContained20,
  iconContained21,
  iconContained22,
  iconContained23,
  iconContained24,
  iconContained25,
  iconContained26,
  iconContained27,
  iconContained28,
  iconContained29,
  signalLogo,
  signalColorLogo,
  academy,
  discord,
  gitbook,
  medium,
  play,
  twitter,
};
