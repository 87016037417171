import "./leftSection.css";
import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Style from "./leftSection.module.css";
import images from "../../../img";
import { MainContext } from "../../../context/context";

function LeftSection({ children }) {
  const [active, setActive] = useState(false);
  const classNameFunc = ({ isActive }) => (isActive ? "active_link" : "");
  const { loginData, setLoginData } = useContext(MainContext);

  const menuItem = [
    {
      path: "/overview",
      name: "Overview",
    },
    {
      path: "/markets",
      name: "Markets",
    },
    {
      path: "/collateral",
      name: "Collateral",
    },
    {
      path: "/loans",
      name: "Loans",
    },
    {
      path: "/arbitriage",
      name: "Arbitriage",
    },
    {
      path: "/vaults",
      name: "Vaults",
    },
    {
      path: "/settings",
      name: "Settings",
    },
  ];

  const LogoutBtn = () => {
    localStorage.removeItem("LoginData");
    setLoginData(null);
  };
  return (
    <div className={Style.mainContainer}>
      <div className={Style.leftSection}>
        <div>
          <img
            src={images.signalColorLogo}
            alt="logo"
            className={Style.leftSection_logo}
          />
          <hr
            style={{
              color: "#2E3138",
            }}
          />
          <div className="overll">
            <ul id="mainMenu" className="">
              {menuItem.map((item, index) => {
                return (
                  <li className={Style.leftSection_options_box}>
                    <NavLink
                      to={item.path}
                      key={index}
                      className={Style.leftSection_button_options}
                      style={({ isActive }) => ({
                        color: isActive ? "#2e3138" : "#2e3138",
                        fontWeight: isActive ? "700" : "400",
                      })}
                    >
                      {item.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <button className={Style.leftSection_button} onClick={LogoutBtn}>
          <span>Logout</span>
        </button>
      </div>
      <main className={Style.rightSection}>{children}</main>
    </div>
  );
}

export default LeftSection;
