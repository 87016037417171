import React, { createContext, useState, useEffect } from "react";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [blockChaintest, setBlockChaintest] = useState("Maineet");
  const [protocols, setProtocols] = useState("Maineet");
  const [loginData, setLoginData] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("loginData")) {
      setLoginData(JSON.parse(localStorage.getItem("loginData")));
    }
  }, [localStorage.getItem("loginData")]);
  const value = {
    blockChaintest,
    setBlockChaintest,
    protocols,
    setProtocols,
    loginData,
    setLoginData,
  };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer;
