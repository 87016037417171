import Style from "./landingPage.module.css";
import images from "../../img";
import { useContext } from "react";
import { SiDiscord, SiTwitter, SiGitbook, SiMedium } from "react-icons/si";
import { HiAcademicCap } from "react-icons/hi";

import React from "react";
import { Link } from "react-router-dom";
import { MainContext } from "../../context/context";

const LandingPage = () => {
  const { loginData, setLoginData } = useContext(MainContext);
  return (
    <div className={Style.landingSection}>
      <img
        src={images.signalLogo}
        alt="logo"
        className={Style.landingSection_logo}
      />
      <div className={Style.landingSection_icons_container}>
        <div className={Style.landingSection_icons_inner_container}>
          <img
            src={images.discord}
            alt="discord"
            className={Style.landingSection_icons}
          />
          <img
            src={images.twitter}
            alt="twitter"
            className={Style.landingSection_icons}
          />
          <img
            src={images.gitbook}
            alt="gitbook"
            className={Style.landingSection_icons}
          />
          <img
            src={images.medium}
            alt="medium"
            className={Style.landingSection_icons}
          />
          <img
            src={images.academy}
            alt="academy"
            className={Style.landingSection_icons}
          />
        </div>
      </div>
      <div className={Style.landingSection_main_container}>
        <div className={Style.landingSection_leftside_container}>
          <div className={Style.landingSection_heading_container}>
            <h1 className={Style.landingSection_heading}>Defi Marketplace</h1>
            <p className={Style.landingSection_para}>For Investable Trades</p>
          </div>
          <div className={Style.landingSection_stats_container}>
            <div>
              <p className={Style.landingSection_stats_1}>3,765+</p>
              <p className={Style.landingSection_stats_2}>Total wallets</p>
            </div>
            <div>
              <p className={Style.landingSection_stats_1}>2,096+</p>
              <p className={Style.landingSection_stats_2}>Total vaults</p>
            </div>
            <div>
              <p className={Style.landingSection_stats_1}>$1,452,258+</p>
              <p className={Style.landingSection_stats_2}>Total volume</p>
            </div>
          </div>
          <div className={Style.button_container}>
            {loginData ? (
              <Link to="/overview">
                <button className={Style.landingSection_btn_container2}>
                  Launch DEFI
                </button>
              </Link>
            ) : (
              <Link to="/login">
                <button className={Style.landingSection_btn_container2}>
                  Launch DEFI
                </button>
              </Link>
            )}
            {loginData ? (
              <Link to="/overview">
                <button className={Style.landingSection_btn_container}>
                  Public Sale
                </button>
              </Link>
            ) : (
              <Link to="/login">
                <button className={Style.landingSection_btn_container}>
                  Public Sale
                </button>
              </Link>
            )}
            {/* <button className={Style.landingSection_btn_container}>
              Public Sale
            </button> */}
          </div>
        </div>
        <div className={Style.landingSection_rightside_container}>
          <img
            src={images.highLight}
            alt="highLight"
            className={Style.landingSection_rightside_img}
          />
        </div>
      </div>
      <div className={Style.landingSection_footer_container}>
        <div className={Style.landingSection_footer_main_heading}>
          <h1 className={Style.landingSection_footer_heading}>Chains:</h1>
          <div className={Style.landingSection_footer_container_inner}>
            <img
              src={images.iconContained1}
              alt="iconContained1"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained2}
              alt="iconContained2"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained3}
              alt="iconContained3"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained4}
              alt="iconContained4"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained5}
              alt="iconContained5"
              className={Style.iconContained}
            />
          </div>
        </div>
        <div className={Style.landingSection_footer_main_heading}>
          <h1 className={Style.landingSection_footer_heading}>Integrations:</h1>
          <div className={Style.landingSection_footer_container_inner}>
            <img
              src={images.iconContained11}
              alt="iconContained11"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained12}
              alt="iconContained12"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained13}
              alt="iconContained13"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained14}
              alt="iconContained14"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained15}
              alt="iconContained15"
              className={Style.iconContained}
            />
            <img
              src={images.iconContained16}
              alt="iconContained16"
              className={Style.iconContained}
            />
          </div>
        </div>
        <div className={Style.landingSection_footer_main_heading}>
          <h1 className={Style.landingSection_footer_heading}>Investors:</h1>
          <div className={Style.landingSection_footer_container_inner}>
            <img
              src={images.iconContained20}
              alt="iconContained20"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained21}
              alt="iconContained21"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained22}
              alt="iconContained22"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained23}
              alt="iconContained23"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained24}
              alt="iconContained24"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained25}
              alt="iconContained25"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained26}
              alt="iconContained26"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained27}
              alt="iconContained27"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained28}
              alt="iconContained28"
              className={Style.iconContained1}
            />
            <img
              src={images.iconContained29}
              alt="iconContained29"
              className={Style.iconContained1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
