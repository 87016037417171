import React, { useState } from "react";
import Style from "./settings.module.css";
import images from "../../../../img";

const Settings = () => {
  const [testInput, setTestInput] = useState("shorupan@gmail.com");
  const [editEmail, setEditEmail] = useState(false);
  const [inputName, setInputName] = useState("shorupan@gmail.com");
  const [editName, setEditName] = useState(false);
  return (
    <div className={Style.settingsSection}>
      <h1 className={Style.settingsSection_title}>Settings</h1>
      <div className={Style.settingsSection_box}>
        <div className={Style.settingsSection_box_top}>
          <div className="">
            <p className={Style.settingsSection_box_input_para}>Name</p>
            <div className={Style.testing}>
              <img
                src={images.editIcon}
                alt="edit"
                className={Style.settingsSection_box_input_icon}
                onClick={() => setEditName(true)}
              />
              <input
                type={editName ? "text" : "disabled"}
                className={Style.settingsSection_box_input}
                style={{
                  outline: editName ? "" : "none",
                  cursor: editName ? "text" : "not-allowed",
                }}
                placeholder="Enter Your Name"
                value={inputName}
                onChange={(e) => (editName ? setInputName(e.target.value) : "")}
                onBlur={() => setEditName(false)}
              />
            </div>
          </div>
          <div className="">
            <p className={Style.settingsSection_box_input_para}>Email</p>
            <div className={Style.testing}>
              <img
                src={images.editIcon}
                alt="edit"
                className={Style.settingsSection_box_input_icon}
                onClick={() => setEditEmail(true)}
              />
              <input
                type={editEmail ? "text" : "disabled"}
                className={Style.settingsSection_box_input}
                style={{
                  outline: editEmail ? "" : "none",
                  cursor: editEmail ? "text" : "not-allowed",
                }}
                placeholder="Enter Your Email"
                value={testInput}
                onChange={(e) =>
                  editEmail ? setTestInput(e.target.value) : ""
                }
                onBlur={() => setEditEmail(false)}
              />
            </div>
          </div>
        </div>
        <div className="">
          <p className={Style.settingsSection_box_input_para}>Type</p>
          <div>
            <button className={Style.settingsSection_box_input_btn}>
              Self Managed
            </button>
            <button className={Style.settingsSection_box_input_btn_selected}>
              Automated
            </button>
          </div>
        </div>
        <div className="">
          <p className={Style.settingsSection_box_input_para}>Type</p>
          <div className={Style.testing}>
            <input
              type="text"
              className={Style.settingsSection_box_input_currency_bold}
              placeholder="USD"
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
