import Style from "./loginPage.module.css";
import images from "../../img";
import { MainContext } from "../../context/context";
import { useContext, useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const LoginPage = () => {
  const { loginData, setLoginData } = useContext(MainContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const handleLogin = () => {
    setLoading(true);
    axios
      .post(`https://gxauth.apimachine.com/gx/user/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          setLoginData(res.data);
          localStorage.setItem("bankerEmailNew", res?.data?.user.email);
          localStorage.setItem("LoginData", JSON.stringify(res.data));
        } else {
          setLoading(false);
        }
      });
  };
  return (
    <div className={Style.loginSection}>
      {loading ? (
        <ClipLoader
          color={color}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className={Style.loginSection_box}>
          <img
            src={images.signalColorLogo}
            alt="logo"
            className={Style.loginSection_box_logo}
          />
          <div className={Style.loginSection_box_inputSection}>
            <input
              type="text"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={Style.loginSection_box_input}
            />
            <input
              type="password"
              placeholder="Enter Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={Style.loginSection_box_input}
            />
          </div>
          <button
            className={Style.loginSection_box_button}
            onClick={handleLogin}
          >
            <span>Login</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
