import React from "react";
import Style from "./collateral.module.css";

const Collateral = () => {
  const data = [
    {
      coin: "Bitcoin",
    },
    {
      coin: "Ethereum",
    },
    {
      coin: "Tether",
    },
    {
      coin: "USDC",
    },
    {
      coin: "Solana",
    },
  ]
  return (
    <div className={Style.collateralSection}>
      
      <div className={Style.collateralSection_top_box}>
        <div>
          <p className={Style.collateralSection_top_box_text1}>
            Collateral Assets
          </p>
          <p className={Style.collateralSection_top_box_text2}>$1,315,313,31</p>
        </div>
        <div>
          <p className={Style.collateralSection_top_box_text1}>
            Utilization Percentage
          </p>
          <p className={Style.collateralSection_top_box_text3}>43.23%</p>
        </div>
      </div>
      {data.map((item, index) => {
        return (
          <div className={Style.collateralSection_bottom_box}>
            <div className={Style.collateralSection_bottom_para_container}>
              <p className={Style.collateralSection_bottom_box_para}>Asset:</p>
              <p className={Style.collateralSection_bottom_box_para}>{item.coin}</p>
            </div>
            <hr />
            <div className={Style.collateralSection_bottom_para_container}>
              <p className={Style.collateralSection_bottom_box_para}>Amount</p>
              <p className={Style.collateralSection_bottom_box_para}>23,3135</p>
            </div>
            <hr />
            <div className={Style.collateralSection_bottom_para_container}>
              <p className={Style.collateralSection_bottom_box_para}>
                Current Value:
              </p>
              <p className={Style.collateralSection_bottom_box_para}>
                $2,313,313,31
              </p>
            </div>
            <hr />
            <div className={Style.collateralSection_bottom_para_container}>
              <p className={Style.collateralSection_bottom_box_para}>
                Avg Interest Rate:
              </p>
              <p className={Style.collateralSection_bottom_box_para}>12.35%</p>
            </div>
            <hr />
            <div className={Style.collateralSection_bottom_para_container}>
              <p className={Style.collateralSection_bottom_box_para}>
                Utilization Ratio
              </p>
              <p className={Style.collateralSection_bottom_box_para}>39.41%</p>
            </div>
          </div>
        )
      })
      }
    </div>
  );
};

export default Collateral;
