import "./App.css";
import MainContextProvider from "./context/context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routing from "./Routes";
import Main from "./Components/Main";
import LoginPage from "./pages/LoginPage/loginPage";
import LandingPage from "./pages/LandingPage/landingPage";
import Analysis from "./Components/analysis/Analysis";
import CoinsData from "./Components/coinsData/CoinsData";
import Swap from "./Components/swap/swap";
import { Navigate } from "react-router-dom";
import { MainContext } from "./context/context";
import { useContext } from "react";

function App() {
  const { loginData, setLoginData } = useContext(MainContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/login"
          element={loginData !== null ? <Routing /> : <LoginPage />}
        />
        {/* <Route
          path="/landingPage"
          element={loginData !== null ? <LandingPage /> : <LoginPage />}
        /> */}

        <Route
          path="/*"
          element={loginData !== null ? <Routing /> : <Navigate to="/login" />}
        />
        <Route exact path="/" element={<Routing />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
