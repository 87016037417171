import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage/loginPage";
import Main from "./Components/Main";
import LeftSection from "./pages/DashBoard/LeftSection/leftSection";
import Settings from "./pages/DashBoard/RightSection/Settings/settings";
import Vaults from "./pages/DashBoard/RightSection/Vaults/Vaults";
import Arbitriage from "./pages/DashBoard/RightSection/Arbitriage/Arbitriage";
import Loans from "./pages/DashBoard/RightSection/Loans/Loans";
import Collateral from "./pages/DashBoard/RightSection/Collateral/Collateral";
import Markets from "./pages/DashBoard/RightSection/Markets/Markets";
import Overview from "./pages/DashBoard/RightSection/Overview/overview";

function Routing() {
  return (
    <LeftSection>
      <Routes>
        <Route path="/" element={<Navigate to="/overview" />} />
        <Route exact path="/overview" element={<Overview />} />
        <Route exact path="/markets" element={<Markets />} />
        <Route exact path="/collateral" element={<Collateral />} />
        <Route exact path="/loans" element={<Loans />} />
        <Route exact path="/arbitriage" element={<Arbitriage />} />
        <Route exact path="/vaults" element={<Vaults />} />
        <Route exact path="/settings" element={<Settings />} />
      </Routes>
    </LeftSection>
  );
}

export default Routing;
